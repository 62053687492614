import * as React from 'react';
import * as CommonMark from 'commonmark';
import PageHeader from '../../Components/PageHeader';
import {Card, Header, Image, Responsive} from 'semantic-ui-react';
import { returnDesktopLayout, returnMobileLayout } from '../../Components/Functions/functions';
import ProdorJpg from './images/Prodor.jpg';
import WPMTestJpg from './images/WPMTest.jpg'


interface IState {
  returnedMarkDown: {
    [key: string]: {markdown: string,  link: string};
  };
}
 

class ProjectsPage extends React.Component  <{}, IState>  {
  markdownURLs: Array<{url: string, name: string, link: string}> = [
    {url: "https://github.com/Scoombe/cmdTyping/blob/typingVersion2/README.md", name: "cmdTyping", link: 'https://github.com/Scoombe/cmdTyping/'},
    {url: "https://github.com/Scoombe/wpmTest/blob/master/README.md", name: "wpm", link: "https://github.com/Scoombe/wpmTest"}
  ];
  projects: Array<{description: string, heading: string, image: string, link: string}> = [
    {
      description: "A productivity app using Typescript, React, Firebase and the Semantic UI library.  Prodor uses different productivity tools like goals, a day planner and todo list to organise producitivity. This uses continous deploying using Gitlab CI tools and Netlify.",
      heading: "Prodor",
      image: ProdorJpg,
      link: "https://todo.scoombedev.com/",
    },
    {
      description: " A words per minute typing test app using Typescript, React, Firebase and the semantic ui library. The WPM test app creates a group or random words that the user has to type out. The app captures the user's key inputs. Users can also upload their own character sequence to set leader boards with other users and can view other users' top scores.",
      heading: "WPM Test",
      image: WPMTestJpg,
      link: "https://typing.scoombedev.com/"
    }
  ];

  reader = new CommonMark.Parser();
  writer = new CommonMark.HtmlRenderer();
  constructor(props: {}) {
    super(props);
    this.fetchMarkdownFromUrl = this.fetchMarkdownFromUrl.bind(this);
    this.state = {
        returnedMarkDown: {}
    };
    this.fetchMarkdownFromUrl();
  }
  public render() {
    return (
      <Responsive>
        <PageHeader page="/projects"/>
        <Responsive minWidth={Responsive.onlyTablet.minWidth} as={React.Fragment}>
          {returnDesktopLayout(this.returnHeader())}
          {returnDesktopLayout(this.returnProjects())}
        </Responsive>
        <Responsive {...Responsive.onlyMobile} as={React.Fragment}>
          {returnMobileLayout(this.returnHeader())}
          {returnMobileLayout(this.returnProjects())}
        </Responsive>
      </Responsive>
    )
  }

  private returnHeader(): JSX.Element {
    return (
      <Header>
        My Personal Projects
        <Header.Subheader>
          Some of my personal projects that I have completed outside of Professional work
        </Header.Subheader>
      </Header>
    )
  }
  private returnProjects(): JSX.Element {
    return (
      <Card.Group>
        {this.renderProjects()}
        {this.renderMarkdown()}
      </Card.Group>
    )
  }

  private renderMarkdown (): JSX.Element[] {
    const { returnedMarkDown } = this.state;
    const cardGroup: JSX.Element [] = [];
    for (const markdownKey in returnedMarkDown) {
      cardGroup.push(
        (
          <Card
            fluid={true}
            header = {markdownKey}
            description = {returnedMarkDown[markdownKey].markdown}
            href = {returnedMarkDown[markdownKey].link}
          />
        )
      )
    }
    return cardGroup;
  }
  private renderProjects (): JSX.Element[] {
    const cards: JSX.Element[] = []
    for (const project of this.projects) {
      cards.push(
        (
          <Card  key={project.heading} fluid={true} href={project.link}>
            <Image  src={project.image}  bordered={true} fluid={true} />
            <Card.Content>
              <Card.Header>
                {project.heading}
              </Card.Header>
              <Card.Description>
                {project.description}
              </Card.Description>
            </Card.Content>
          </Card>
        )
      )
    }
    return cards;
  }
  private fetchMarkdownFromUrl() {
    this.markdownURLs.forEach(url => {
  
      fetch("https://cors-anywhere.herokuapp.com/" + url.url).then(response => response.body)
      .then(body => {
        if (body) {
          const reader = body.getReader();
        console.log(reader);
        }
        
      })
    
      // console.log(response.json())})
      // .then(data => {
      //   // Here's a list of repos!
      //   console.log(data)
      // });
          // console.log(result);
          // var parsed = this.reader.parse(result);
          // returnedMarkDown[url.name] = {markdown: this.writer.render(parsed), link: url.link};
          // this.setState({
          //     returnedMarkDown: returnedMarkDown
          // });
        // });
    });
  }
}

export default ProjectsPage;
