import * as React from 'react';
import * as ReactRouter from 'react-router-dom';
import  {Accordion, Grid, Header, Icon, Menu, Responsive, Segment } from 'semantic-ui-react';

interface IProps extends ReactRouter.RouteComponentProps {
  page: string;
}

class PageHeader extends React.Component<IProps> {
  constructor(props:IProps) {
    super(props)
    this.navigatePage = this.navigatePage.bind(this);
    this.returnMenuItems = this.returnMenuItems.bind(this);
  }
  public render() {
    return (
      <Responsive className="todo-header">
        <Segment inverted={true}>
          <Responsive minWidth={Responsive.onlyTablet.minWidth} as={React.Fragment}>
            {this.returnMenuItems(false)}
          </Responsive>
          <Responsive {...Responsive.onlyMobile} as={React.Fragment}>
            {this.mobileAccordian()}
          </Responsive>
          <Header inverted={true} as="h1">
            <Header.Content>
              Scoombe Development
            </Header.Content>
            <br/><br/>
            <Header.Subheader> 
              Personal Portfolio
            </Header.Subheader>
          </Header>
        </Segment>
      </Responsive>
    );
  }
  private returnMenuItems (vertical: boolean) {
    const { page } = this.props
    return (
      <Menu className="header-menu" borderless={true} inverted={true} vertical={vertical} fluid={vertical}>
        <Menu.Item 
          hidden={page === '/'}
          data-page=""
          onClick={this.navigatePage}>
          Home
        </Menu.Item>
        <Menu.Item
          data-page="projects"
          onClick={this.navigatePage}>
          Projects
        </Menu.Item>
        <Menu.Item 
          data-page='skills'
          onClick={this.navigatePage}>
          My Skills
        </Menu.Item>
      </Menu>
    )
  }

  private mobileAccordian(): JSX.Element {
    const panels = [
      {
        content: {
          content: this.returnMenuItems(true),
        },
        key: '',
        title: {
          content: (<Icon name="bars"/>),
        },
      },
    ];
    return(
      <Grid>
        <Grid.Row columns="1" centered={true}>
          <Grid.Column>
            <Accordion inverted={true} panels={panels}/>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  private navigatePage(e: any) {
    const page = e.currentTarget.dataset.page;
    const { history } = this.props;
    history.push(`/${page}`);
  }
}

export default ReactRouter.withRouter(PageHeader);