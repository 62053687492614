import React from 'react';
import ReactDOM from 'react-dom';
import * as router from 'react-router-dom';
import './index.css';
import App from './Components/App';
import 'semantic-ui-css/semantic.min.css';
import * as serviceWorker from './serviceWorker';

ReactDOM.render
(
<router.BrowserRouter>
    <App />
</router.BrowserRouter>
,
document.getElementById('root'));

serviceWorker.unregister();
