import React from 'react';
import * as ReactRouter from 'react-router-dom';
import LandingPage from '../../Pages/LandingPage';
import ProjectsPage from '../../Pages/ProjectsPage';
import SkillsPage from '../../Pages/SkillsPage';
import './App.css';

class App extends React.Component<{}, {}> {
  public render() {
    return (
      <div className="App">
        <ReactRouter.Switch>
          <ReactRouter.Route exact={true} path="/projects" component={ProjectsPage} />
          <ReactRouter.Route exact={true} path="/skills" component={SkillsPage} />
          <ReactRouter.Route exact={false} path="/" component={LandingPage} />
        </ReactRouter.Switch>
      </div>
    );
  }
}

export default App;
