import * as React from 'react';
import { Header, List, Responsive } from 'semantic-ui-react';
import { returnMobileLayout, returnDesktopLayout } from '../../Components/Functions/functions';
import PageHeader from '../../Components/PageHeader';

class SkillsPage extends React.Component {
  public render() {
    return (
      <Responsive>
        <PageHeader page="/skills"/>  
        <Responsive minWidth={Responsive.onlyTablet.minWidth} as={React.Fragment}>
          {returnDesktopLayout(this.returnHeader())}
          {returnDesktopLayout(this.returnContent())}
        </Responsive>
        <Responsive {...Responsive.onlyMobile} as={React.Fragment}>
          {returnMobileLayout(this.returnHeader())}
          {returnMobileLayout(this.returnContent())}
        </Responsive>
      </Responsive>

    )
  }
  public returnHeader():JSX.Element {
    return (
      <Header>
        My Skills
      </Header>
    )
  }
  public returnContent(): JSX.Element {
    return (
      <List>
        <List.Item>
          <List.Header>
            Languages
        </List.Header>
          <List.Content>
            I have strong JavaScript, TypeScript and C# experience and I'm familiar with python.
        </List.Content>
        </List.Item>
        <List.Item>
          <List.Header>
            Frame works
        </List.Header>
          <List.Content>
            I have strong skills in React, JQuery. I've used MVC for different applications.
        </List.Content>
        </List.Item>
        <List.Item>
          <List.Header>
            Database management
          </List.Header>
          <List.Content>
            I have strong MySQL and Firebase knowledge.
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Header>
            Education
          </List.Header>
          <List.Content>
            I graduated from the University of Roehampton in 2020 with a 2:1 in Digital and Technical Solutions.
            This gave me experience in technical and non-technical skills, including modules on project management; Internet of things; web and application security.
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Header>
            Dev Ops
          </List.Header>
          <List.Content>
            I'm familliar with the principles of DevOps having gained experience from deploying various applications, as seen on my projects page.
          </List.Content>
        </List.Item>
      </List>
    )
  }
}
export default SkillsPage
