import * as React from 'react';
import PageHeader from '../../Components/PageHeader'
import { Button, Container, Header, Image, Responsive} from 'semantic-ui-react';
import { returnDesktopLayout, returnMobileLayout } from '../../Components/Functions/functions';
import GitHubLogoPng from './images/GitHubLogo.png';

class LandingPage extends React.Component {
  public render() {
    return (
      <Responsive>
        <PageHeader page="/"/>
        <Responsive minWidth={Responsive.onlyTablet.minWidth} as={React.Fragment}>
          {returnDesktopLayout(this.returnContent())}
        </Responsive>
        <Responsive {...Responsive.onlyMobile} as={React.Fragment}>
          {returnMobileLayout(this.returnContent())}
        </Responsive>
      </Responsive>
    )
  }
  public returnContent(): JSX.Element {
    return(
      <React.Fragment>
        <Container>
          <Header>
            About me
          </Header>
          <p> I'm Sam and I'm currently working at Cisco, as part of the RTMS team, as a Developer. Previously I have been 
            at IMI and Rostrvm solutions. At both companies, I worked in the front end development teams.</p>
          <p> I'm passionate about Javascript development using the browser and Node.</p>
          <p> I'm a fan of continuous integration, development and deployment. I use the principles and tools as much as I can.</p>
          <p> Please contact me and check out my github page.</p>
          <br/>
        </Container>
        <Container textAlign="center">
          <Button  as="a" className="btn" basic href="https://www.github.com/scoombe">
              <Image src={GitHubLogoPng} alt="Github logo" size="tiny"/>
          </Button>
        </Container>
      </React.Fragment>
    )
  }
}

export default LandingPage;
