import React from "react";
import {Grid} from 'semantic-ui-react'

function returnDesktopLayout(content: JSX.Element):JSX.Element {
  return (
    <Grid>
      <Grid.Row>
      </Grid.Row>
      <Grid.Row columns="2" centered={true}>
        <Grid.Column>
          {content}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

function returnMobileLayout (content: JSX.Element) {
  return (
    <Grid>
      <Grid.Row columns="1">
        <Grid.Column>
          {content}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export {returnMobileLayout, returnDesktopLayout};